<template>
<div>
  <div class="main">
    <div class="container">
      <h1>
        登入<a href="javascript:void(0);" @click="showRegister"
          >申請帳號</a
        >
      </h1>
      <div class="formBox">
        <div class="unLogin">
          <div class="formRow">
            <h3>帳號 *</h3>
            <input id="account" type="text" required v-model="Login.UserName" />
          </div>
          <div class="formRow">
            <h3>密碼 *</h3>
            <input
              id="password1"
              type="password"
              required
              v-model="Login.Password"
            />
          </div>
          <div class="submitBtn" @click="submit()">
            <a href="javascript:void(0);">登入</a>
          </div>
          <p class="otherLink">
            <a href="javascript:void(0);" @click="$router.push('/forget')"
              >忘記密碼</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
  
    
    <div id="registerDialog" class="popUp hide">
      <div class="alertWindow jcWindow">
        <div class="p_head">
          <p>申請帳號</p>
        </div>
        <div class="p_content">
          <p>您好，請選擇您欲申請的帳號之身份別：</p>
          <div class="joinChoose">
            <a href="javascript:void(0)" @click="$router.push('/register')"
              ><img src="img/jc_01.jpg" />
              <h4>一般使用者</h4>
              <p>
                供一般民眾申請，通過申請即可於本網站觀看相關課程影片(部分影片須付費)
              </p></a
            ><a href="javascript:void(0)" @click="$router.push('/registerp')"
              ><img src="img/jc_02.jpg" />
              <h4>專家委員</h4>
              <p>如：計劃委員、委員會召集人、委員會委員</p></a
            ><a href="javascript:void(0)" @click="$router.push('/registerl')"
              ><img src="img/jc_03.jpg" />
              <h4>研習上課</h4>
              <p>如：證照Renew/...等</p></a
            >
          </div>
        </div>
        <div class="p_foot">
          <a href="javascript:void(0)" @click="hideRegister">取消</a>
        </div>
      </div>
    </div>
</div>
</template>
<script>
import user from "../plugins/user";

export default {
  data() {
    return {
      Login: {
        UserName: "",
        Password: "",
      },
    };
  },
  mounted() {
    this.setBase("login");
    this.loadCustomJs();
  },
  methods: {
    async submit() {
      if (!this.validate(".unLogin")) {
        return;
      }
      try {
        var result = await this.$api.login(this.Login);
        user.setUser(result);
        this.$api.setToken(result.t);
        window.location.href = "/";
        //this.$router.replace("/lessons");
      } catch (e) {
        console.error(e);
        if (e.Status == 6) {
          alert('登入失敗，此帳號契約時間已過期');
        } else {
          alert("登入失敗，帳號或密碼錯誤");
        }
      }
    },
    showRegister() {
      $("#registerDialog").removeClass("hide");
    },
    hideRegister() {
      $("#registerDialog").addClass("hide");
    },
  },
};
</script>

